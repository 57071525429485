import React from "react";

import Cat from '../assects/right-img.jpeg'
import Cat2 from '../assects/center1.jpeg'
import Cat3 from '../assects/right-img.jpeg'
import './Catogires.css'

const Categories = () => {
    return (
        <div>
            <div className="small-slider">
                <div className="small-container">
                    <div className="small-img">
                        <img src={Cat} alt="ashwagandha powder"></img>
                        <div className="overlay-cat"><p></p></div>
                        </div>
                    <div className="small-img">
                        <img src={Cat2} alt="moringa powder"></img>
                        <div className="overlay-cat"><p></p></div>
                        </div>
                    <div className="small-img">
                        <img src={Cat3} alt="stevia powder"></img>
                        <div className="overlay-cat"><p></p></div>
                        </div>
                </div>
            </div>
        </div>
    )
};

export default Categories;