import React from "react";
import './Ourproduct.css';

import ashwagandha1 from '../assects/left1.jpeg'
import ashwagandha2 from '../assects/right1.jpeg'
import ashwagandha3 from '../assects/pro-img1.jpeg'

import Moringa1 from '../assects/pro-img2.jpeg'
import Moringa2 from '../assects/pro-img3.jpeg'
import Moringa3 from '../assects/pro-img4.jpeg'

import LeftTshirt from '../assects/Left-Tshirt.jpeg'
import CTshirt from '../assects/center-1.jpeg'
import RightTshirt from '../assects/right-T.jpeg'


const Ourproduct = () => {
    return (

        <div id="our products">
            <div className="products">
                <div className="header">
                    <h1>OUR PRODUCTS</h1>
                </div>
                <div>


                    <div className="productrow">
                        <h2>ToteBags</h2>
                        <div className="para">
                            <p>Our canvas and 100% cotton tote bags epitomize elegance and functionality, perfect for those who appreciate both style and practicality. Crafted with high-quality materials, these tote bags are designed to meet your daily needs while adding a touch of sophistication to your ensemble.</p>
                        </div>
                        <div className="img-wrap">

                            <div className="product-box"><div>
                                <div className="img-container">
                                    <div className="img-contant">
                                        <img className='ashwagandha1-img' src={ashwagandha1} alt="this is img 1"></img>
                                        <div className="overlay"><p>
                                        </p></div></div>
                                </div>
                                <div class="product-title">Printed Bags</div>
                                {/* <div className="addcard-btn">read more</div> */}
                                <div className="hover-text">Text on hover</div>
                            </div>
                            </div>
                            <div className="product-box">
                                <div className="img-container">
                                    <img src={ashwagandha2} alt=""></img>
                                    <div className='overlay2'>
                                        <p>
                                        </p>
                                    </div>
                                </div>
                                <div class="product-title">Elegant Style</div>
                                {/* <div className="addcard-btn">Read more</div> */}
                            </div>
                            <div className="product-box">
                                <div className="img-container">
                                    <img src={ashwagandha3} alt=""></img>
                                    <div className='overlay3'><p>
                                    </p></div>
                                    {/* <img className='alt-img' src="" alt="alternate image "></img> */}
                                </div>
                                <div class="product-title">Classic Bagss</div>
                                {/* <div className="addcard-btn">Read More</div> */}
                            </div>
                        </div>


                    </div>
                    <div className="productrow">
                        {/* <h2>Co-ord Set</h2> */}
                        <p className="para">Our tote bags boast an elegant design that seamlessly blends with any outfit, whether you're heading to work, running errands, or enjoying a casual day out. The clean lines and minimalist aesthetic make these bags versatile and timeless, suitable for any occasion. The attention to detail in the stitching and finishing touches showcases the craftsmanship and quality that go into every bag.</p>
                        <div className="img-wrap">
                            <div className="product-box">
                                <div className="img-container"> <img src={Moringa1} alt=""></img>
                                    <div className='overlay'><p>
                                    </p></div>
                                    {/* <img className='alt-img' src="" alt="alternate image "></img> */}
                                </div>

                                <div class="product-title">Customise Bags</div>
                                {/* <div className="addcard-btn"> read more</div> */}
                            </div>
                            <div className="product-box">
                                <div className="img-container">  <img src={Moringa2} alt=""></img>
                                    <div className='overlay2'><p>
                                    </p></div>
                                </div>

                                <div class="product-title">Plane Bags</div>
                                {/* <div className="addcard-btn">read more</div> */}
                            </div>
                            <div className="product-box">
                                <div className="img-container">  <img src={Moringa3} alt=""></img>
                                    <div className='overlay3'><p>
                                    </p></div>
                                </div>

                                <div class="product-title">Phonk Bags</div>
                                {/* <div className="addcard-btn">read more</div> */}
                            </div>
                        </div>
                    </div>


                    <div className="productrow">
                        <h2>T-Shirts</h2>
                        <p className="para">Each t-shirt is made from the finest fabrics, ensuring a soft and comfortable feel against your skin. The use of high-quality cotton guarantees breathability and moisture-wicking properties, keeping you cool and comfortable throughout the day. The durable material ensures that the t-shirts retain their shape and color even after multiple washes, providing long-lasting wear.</p>
                        <div className="img-wrap">
                            <div className="product-box">
                                <div className="img-container"> <img src={LeftTshirt} alt=""></img>
                                    <div className='overlay'><p>
                                    </p></div>
                                    {/* <img className='alt-img' src="" alt="alternate image "></img> */}
                                </div>

                                <div class="product-title">UNISEX T-Shirt</div>
                                {/* <div className="addcard-btn"> read more</div> */}
                            </div>
                            <div className="product-box">
                                <div className="img-container">  <img src={CTshirt} alt=""></img>
                                    <div className='overlay2'><p>
                                    </p></div>
                                </div>

                                <div class="product-title">Over-Sized T-Shirt</div>
                                {/* <div className="addcard-btn">read more</div> */}
                            </div>
                            <div className="product-box">
                                <div className="img-container">  <img src={RightTshirt} alt=""></img>
                                    <div className='overlay3'><p>
                                    </p></div>
                                </div>

                                <div class="product-title">Fit Shirts</div>
                                {/* <div className="addcard-btn">read more</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ourproduct;