import React from "react";
import './certificate.css'
import indiaorg from '../assects/India organic logo.png'
import Usda from '../assects/USDA logo.png'

const Certificate = () => {
    return (
        <div>
            <div className="cr">
                <div className="cr-heading">
                    <h1>Quality Standards</h1>
                    <div className="cr-para"><p>
                    At [company Name], we take pride in upholding the highest standards of quality in every garment we produce. From the initial design stage to the final stitch, our dedicated team ensures that each piece meets our stringent quality control measures. We believe that quality is not just a feature; it's a promise we make to our customers.</p></div>
                </div>
                <div className="certi-img">
                    <img src={indiaorg} alt=""></img>
                    <img src={Usda} alt=""></img>

                </div>
                {/* <div className="certificate"><img src={cetrifications}></img></div> */}
            </div>

        </div>
    );
};

export default Certificate;