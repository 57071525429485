import './App.css';
import Navbar from './components /Navbar';
import Header from './components /Header';
import Categories from './components /Catogires';
import Slider from './components /Slider';
import Aboutus from './components /Aboutus';
import BackgroundVideo from './components /Video';
import Ourproduct from './components /Ourproduct';
import Committed from './components /Committed';
import Contact from './components /Contact';
import Certificate from './components /certificate';



function App() {
  return (
    <div className="App">
      <Navbar />

      <Categories />

      <Header />

      <Slider />

      <Ourproduct />

      <Certificate />

      {/* <BackgroundVideo /> */}

      <Aboutus />

      <Committed />

      <Contact />

    </div>

  );
}

export default App;
