import React from "react";
import aboutus from '../assects/CHAOS.png'
// import world from './world-environment-earth-day-concept-generative-ai(1).jpg'

import './Aboutus.css';

const Aboutus = () => {
    return (
        <div id="aboutus">
            <div className="aboutus">
                <h1 className="abouthead">ABOUT US</h1>
                <div className="about-image">
                    <img src={aboutus} alt="About Company" />
                    <div className="about-text">
                        <p>Introducing to CHAOS, where elegance meets everyday practicality.
                            We specialize in crafting high-quality tote bags and t-shirts that
                            combine timeless style with exceptional comfort and functionality.
                            Our tote bags, made from durable canvas and 100% cotton, are
                            designed for strength and sophistication, perfect for any occasion.
                            Our t-shirts, crafted from the finest fabrics, offer supreme softness
                            and come in classic and contemporary designs. Committed to quality
                            and sustainability, we use eco-friendly materials and superior construction techniques.
                            We aim to provide an exceptional customer experience, ensuring
                            satisfaction from exploration to delivery. Discover the elegance and functionality
                            of CHAOS tote bags and t-shirts, and experience the perfect blend of style, comfort, and practicality.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Aboutus;