import React, { useState, useEffect } from 'react';
import './Slider.css';

const Slideshow = () => {
    // Define an array of image URLs
    const images = [
       "https://ideogram.ai/assets/image/balanced/response/6OvK03USSn6c4h3_i4LFkA",
        "https://ideogram.ai/assets/image/balanced/response/Lfu7cI7DQA26sfEunFZytQ",
        "https://ideogram.ai/assets/image/balanced/response/kH8kIpZhRjSM3x-W3TuIBA",
        "https://ideogram.ai/assets/image/balanced/response/oredRjlkTTiKMYH2fAymug"
    ];

    // State to keep track of the index of the current image
    const [currentIndex, setCurrentIndex] = useState(0);

    // Function to move to the next image
    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        // Set an interval to automatically move to the next image every 3 seconds
        const interval = setInterval(nextSlide, 2000);

        // Clear the interval on component unmount to prevent memory leaks
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='slider'>
        <div className="slideshow">
            <div className='img-slider'>
                <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} className="slide-image" />
            </div>
        </div>
        </div>
    );
};

export default Slideshow;
