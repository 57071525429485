import React from "react";
import './Committed.css';

const commitedTo = () => {
    return (
        <div id="services">
            <div className="committed">
                <div className="committed-h1">
                    <h1>Swadheen Is Committed To</h1>
                </div>
                <div className="containers">
                    <div className="box"><h3>Absolute Qulality</h3>
                        <div><p> Quality is at the heart of everything we do. Our products are meticulously 
                            crafted with premium materials and superior construction techniques to ensure 
                            they stand the test of time. We believe in creating products that you will love
                             to use and wear every day, bringing a sense of elegance and sophistication to your routine. </p></div></div>
                           
                        <div className="box"><h3>Services To All</h3>
                        <div><p>At CHAOS, we strive to provide an exceptional customer experience. 
                            From the moment you explore our products to the time they arrive at your doorstep,
                             we ensure that your journey with us is seamless and satisfying. Our friendly and 
                             knowledgeable customer service team is always here to assist you, ensuring that 
                             your needs are met with the utmost care and attention.</p></div></div>
                           
                            <div className="box"><h3>Sustainable and Eco-Friendly</h3>
                        <div><p>We are also committed to sustainability and eco-friendliness. Our tote bags
                             and t-shirts are made from natural, eco-conscious materials, reflecting our dedication
                              to responsible manufacturing practices. By choosing CHAOS, you are not only
                               investing in high-quality products but also supporting a more sustainable future.</p></div>
                            </div>
                
                    
                </div>
            </div>
        </div>
    );
};

export default commitedTo;