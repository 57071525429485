import React, { useEffect, useState } from "react";
import "./Navbar.css"
import Logo from '../assects/CHAOS.png'



const Navbar = () => {
    const [shrink, setShrink] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShrink(true);
            } else {
                setShrink(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [isHovered, setIsHovered] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="nav">
            
            <nav className={shrink ? 'navbar shrink' : 'navbar'}>
   
                <div className="navbar">

                    <ul className="nav-items">
            
                       <div className="nav-left"><li className="nav-list"><a href="#">Home</a></li>
                        <li className="nav-list">
                            <div className="drop-down"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}>
                                <div className="hover-element"><a href="#our products">Products</a></div>
                                <div className="dropdown-content">
                                    <a href='#'>ToteBags</a>
                                    <a href='#'>TShirts</a>
                                    <a href='#'></a>
                                    </div>
                            </div></li>
                        <li className="nav-list"><a href="#services">Committed TO</a></li>
                        <div className="top-logo">
                              <img src={Logo} alt="" className="toplogo" />
                            </div>
                        </div> 
                        <div className="nav-right">
                        <li className="nav-list"><a href="#aboutus">About Us</a></li>
                        <li className="nav-list"><a href="#contactus">Contact Us</a></li>
                        <li className="nav-list"><a href="https://www.amazon.in/?&tag=googhydrabk1-21&ref=pd_sl_7hz2t19t5c_e&adgrpid=155259815513&hvpone=&hvptwo=&hvadid=674842289437&hvpos=&hvnetw=g&hvrand=10994797013207820378&hvqmt=e&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9299777&hvtargid=kwd-10573980&hydadcr=14453_2316415&gad_source=1">Store</a></li>
                        </div>
                    </ul>
                
                    <label for="menu-toggle" class="menu-icon">&#9776;</label>

                    {/* <div className="categories">
                        <FaReact />
                        <li><a href="#categories" >Categories</a></li>
                    </div> */}
                </div>
            </nav>
        </div>
    );
}

export default Navbar;