import React from "react";
import './Header.css'
// import animatedlogo from './logodesign2.png'
// import filogo from '../assects/fina logo.jpeg'
// import index from '../assects/indexlogo.jpeg'
// import logo from '../assects/swaadheenlogo.png'
import logo from '../assects/CHAOS.png'

const Header = () => {
    return (
        <div className="homepg">
            {/* <div className="head">
                <h1>CHAOS
                </h1>
                <br />
                <div className="slogan">
                <h2>Embrace The Unexpected</h2>
                </div>
            </div> */}
            <div className="animate-logo">
                <img src={logo} alt=" animated logo" />
            </div>
        </div>
    );
};

export default Header;